import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { baseUrl, webUrl } from './variables';

function UserInfoModal(props) {

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [updateDoc, setUpdateDoc] = useState(0);
  // const [usershippingfee, setUsershippingfee] = useState("off"); // on, off. [IMP: Also enable in app.php $usershippingfee = "off";]

  // const [paymenttimer, setPaymenttimer] = useState(false);
  // const [show, setShow] = useState(true);

  /*
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }; */


  const handleSubmit = async (event) => {
    /*
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('message', message);
    formData.append('type', "new");
    formData.append('me', props.me);
    formData.append('idToCall', props.idToCall);
    formData.append('memberId', props.memberId);
    formData.append('calltype', props.calltype);
    formData.append('roomRowId', props.roomRowId);
    formData.append('roomId', props.roomId);
    formData.append('caller', props.caller);

    // console.log("submit report user", formData);
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    try {
      const response = await fetch(webUrl + "/reportUser.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: formData,
        // headers: {
        // 	'Content-Type': 'application/json',
        // },
        // body: JSON.stringify(requestData),
      });
      setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          console.log("success");
          setNotification('Form submitted successfully');
          setShowAlert(true);
          props.onHide();
        } else {
          console.log("error");
          setNotification('Error encountered while submitting');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error uploading file:', error);
      // Handle error
    } */

  }


  const handleModalClose = () => {
    // props.onHide();
    setFile(null);
    setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };

  useEffect(() => {


    const handleRadioClick = async (event) => {
      const value = event.currentTarget.getAttribute('data-value');
      const type = event.currentTarget.getAttribute('data-type');
      console.log('Clicked:', event.currentTarget.innerText, type, value);

      if (type == "user_adrs") {
        let adr_form = document.querySelectorAll('.new-address-form');
        if (value == "new") {
          adr_form[0].classList.remove("hide");
          adr_form[0].classList.add("show");
        } else {
          adr_form[0].classList.remove("show");
          adr_form[0].classList.add("hide");
        }
      }
    };

    // Add event listeners to the buttons after HTML content is set
    const handleButtonClick = async (event) => {
      const value = event.currentTarget.getAttribute('data-value');
      console.log('Clicked:', event.currentTarget.innerText, value);
      let catgrp = document.querySelectorAll('.categories-group');
      let showcatbtn = document.querySelectorAll('.show-categories');
      let prodgrp = document.querySelectorAll('.products-group');
      let received = document.querySelectorAll('.received-group');

      let proddetgrp = document.querySelectorAll('.product-detail-group');
      let allproddet = document.querySelectorAll('.product-detail');
      let allredeem = document.querySelectorAll('.redeem-product');
      let rewardgrp = document.querySelectorAll('.reward-group');
      let allrewardChoices = document.querySelectorAll('.choose-reward');
      let submitChoice = document.querySelectorAll('.submit-choice');
      let showprodbtn = document.querySelectorAll('.show-products');
      let confirmRedeemProd = document.querySelectorAll('.confirm-redeemprod');
      let shippingDetails = document.querySelectorAll('.shipping-details');
      let shippingfee = document.querySelectorAll('.shippingfee');
      let congrats = document.querySelectorAll('.congrats');
      let confirmSpintowin = document.querySelectorAll('.confirm-spintowin');
      let progressSpintowin = document.querySelectorAll('.progress-spintowin');


      if (value == "show-categories") {
        catgrp[0].classList.add("show");
        received[0].classList.remove("show");
        showcatbtn[0].classList.remove("show");
      }

      if (value == "show-products") {
        const cat = event.target.getAttribute('data-category');
        prodgrp[0].classList.remove("show");
        let catprodgrp = document.querySelectorAll('.products-group[data-category="' + cat + '"]');
        catprodgrp[0].classList.add("show");
        catgrp[0].classList.remove("show");
        // received[0].classList.remove("show");
        // showprodbtn[0].classList.remove("show");
      }

      if (value == "close-categories") {
        catgrp[0].classList.remove("show");
        received[0].classList.add("show");
        showcatbtn[0].classList.add("show");
      }

      if (value == "close-products") {
        prodgrp.forEach(element => {
          element.classList.remove('show');
        });
        catgrp[0].classList.add("show");
        // prodgrp[0].classList.remove("show");
        // received[0].classList.add("show");
        // showcatbtn[0].classList.add("show");
        // showprodbtn[0].classList.add("show");
      }

      if (value == "open-product") {
        const prodid = event.target.getAttribute('data-prodid');
        // console.log('prodid:', prodid, '.product-detail-'+prodid+'');
        prodgrp.forEach(element => {
          element.classList.remove('show');
        });

        // prodgrp[0].classList.remove("show");


        //// 
        // load product details of this product in .proddetgrp
        load_product_details(prodid, "open");

        // on success

        // proddetgrp[0].innerHTML = "hello";
        // let proddet = document.querySelectorAll('.product-detail-' + prodid + '');
        // allproddet.forEach((det) => {
        //   det.classList.remove('show');
        // });
        // proddet[0].classList.add("show");


        ////

      }

      if (value == "close-product-detail") {

        const prodid = event.target.getAttribute('data-prodid');
        const cat = event.target.getAttribute('data-category');
        let proddet = document.querySelectorAll('.product-detail-' + prodid + '');
        proddet[0].classList.remove("show");
        prodgrp.forEach(element => {
          element.classList.remove('show');
        });
        let catprodgrp = document.querySelectorAll('.products-group[data-category="' + cat + '"]');
        catprodgrp[0].classList.add("show");

        // allproddet.forEach((det) => { 
        //   det.classList.remove('show');
        // });
        // prodgrp[0].classList.add("show");
      }

      if (value == "redeem-product") {
        const prodid = event.target.getAttribute('data-prodid');
        let prodredeem = document.querySelectorAll('.redeem-product-' + prodid + '');

        allrewardChoices.forEach((red) => {
          red.classList.remove('active');
        });

        allredeem.forEach((red) => {
          red.classList.remove('active');
        });

        prodredeem[0].classList.add("active");

        confirmRedeemProd[0].classList.add("show");

        // submitChoice[0].classList.add("show");

      }

      if (value == "spintowin-product") {
        confirmSpintowin[0].classList.add("show");
      }

      if (value == "yes-spintowin") {
        progressSpintowin[0].classList.add("show");
        let options = event.currentTarget.getAttribute('data-opt');
        setTimeout(() => spinComplete(options), 2500);
      }

      if (value == "no-spintowin") {
        confirmSpintowin[0].classList.remove("show");
      }

      if (value == "yes-redeem") {
        confirmRedeemProd[0].classList.remove("show");
        shippingDetails[0].classList.add("show");
      }

      if (value == "close-shipping-details") {
        shippingDetails[0].classList.remove("show");
      }

      if (value == "no-redeem") {
        confirmRedeemProd[0].classList.remove("show");
      }

      if (value == "view-my-rewards" || value == "close-congrats") {
        congrats[0].classList.remove("show");
        props.onHide();
      }

      if (value == "close-shippingfee") {
        shippingfee[0].classList.remove("show");
        // props.onHide();
      }



      if (value == "choose-reward") {
        const rewardid = event.target.getAttribute('data-rewardid');
        let choose = document.querySelectorAll('.choose-reward-' + rewardid + '');

        allrewardChoices.forEach((red) => {
          red.classList.remove('active');
        });
        allredeem.forEach((red) => {
          red.classList.remove('active');
        });

        choose[0].classList.add("active");

        submitChoice[0].classList.add("show");

      }

      if (value == "prod-color") {
        const prodid = event.target.getAttribute('data-prodid');
        const color = event.target.getAttribute('data-color');
        let choose = document.querySelectorAll('.prod-color-' + prodid + '[data-color="' + color + '"]');
        let all = document.querySelectorAll('.prod-color-' + prodid + '');
        console.log(prodid, '.prod-color-' + prodid + '');

        all.forEach((red) => {
          red.classList.remove('active');
        });
        choose[0].classList.add("active");
      }

      if (value == "prod-size") {
        const prodid = event.target.getAttribute('data-prodid');
        const size = event.target.getAttribute('data-size');
        let choose = document.querySelectorAll('.prod-size-' + prodid + '[data-size="' + size + '"]');
        let all = document.querySelectorAll('.prod-size-' + prodid + '');
        console.log(prodid, '.prod-size-' + prodid + '');

        all.forEach((red) => {
          red.classList.remove('active');
        });
        choose[0].classList.add("active");
      }

      if (value == "submit" || value == "submit-shipping") {

        let reward = document.querySelectorAll('.choose-reward.active');
        let product = document.querySelectorAll('.redeem-product.active');
        let color = document.querySelectorAll('.prod-color.active');
        let size = document.querySelectorAll('.prod-size.active');

        let firstname = document.getElementById('firstname').value;
        let lastname = document.getElementById('lastname').value;
        let country = document.getElementById('country').value;
        let mobile = document.getElementById('mobile').value;
        let address = document.getElementById('address').value;
        let state = document.getElementById('state').value;
        let zip = document.getElementById('zip').value;
        let usernotes = document.getElementById('usernotes').value;
        let address_id = document.querySelector('input[name="user_adrs"]:checked').value;

        let reward_val = "";
        if (reward.length > 0) {
          reward_val = reward[0].getAttribute('data-rewardid');
        }

        let product_val = "";
        let color_val = "";
        let size_val = "";
        if (product.length > 0) {
          product_val = product[0].getAttribute('data-prodid');
          if (color.length > 0) {
            color_val = color[0].getAttribute('data-color');
          }

          if (size.length > 0) {
            size_val = size[0].getAttribute('data-size');
          }

        }

        console.log("submit ", "reward: ", reward_val, "product: ", product_val, color_val, size_val);

        const formData = new FormData();
        formData.append('reward', reward_val);
        formData.append('product', product_val);
        formData.append('color', color_val);
        formData.append('size', size_val);
        formData.append('type', "milestone-redeem");
        formData.append('milestone', props.milestone);
        formData.append('memberId', props.memberid);
        formData.append('firstname', firstname);
        formData.append('lastname', lastname);
        formData.append('country', country);
        formData.append('mobile', mobile);
        formData.append('address', address);
        formData.append('state', state);
        formData.append('zip', zip);
        formData.append('usernotes', usernotes);
        formData.append('address_id', address_id);

        // console.log("submit report user", formData);
        for (const [key, value] of formData.entries()) {
          console.log(key, value);
        }


        try {
          const response = await fetch(webUrl + "/userStats.php", {
            method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
            body: formData,
            // headers: {
            // 	'Content-Type': 'application/json',
            // },
            // body: JSON.stringify(requestData),
          });
          setLoading(false);
          if (response.ok) {
            const result = await response.json();
            console.log("Data:", result);
            if (result.success) {
              console.log("success");

              if (result.address == "taken") {
                console.log("address is taken");
                // alert("address taken");
                document.getElementById('address_taken_msg').classList.add("show");
              }

              //
              else {

                if (value == "submit-shipping") {

                  let get_shippingfee = result.shippingfee;
                  if (get_shippingfee == "on") {
                    window.open(result.paymentUrl, '_blank');
                  }

                  check_for_payment_status(result.memberId, result.milestone, get_shippingfee);
                  shippingDetails[0].classList.remove("show");
                  prodgrp[0].remove();
                  submitChoice[0].remove();
                  received[0].remove();
                  proddetgrp[0].remove();
                  rewardgrp[0].remove();
                  shippingfee[0].classList.add("show");


                  //show congratulations
                  // alert("congratulations");
                  // confirmRedeemProd[0].classList.remove("show");
                  // congrats[0].classList.add("show");
                  // setNotification('Form submitted successfully');
                  // setShowAlert(true);

                }

                else if (value == "submit") {
                  setNotification('Form submitted successfully');
                  setShowAlert(true);
                  props.onHide();
                }
              }

            } else {
              console.log("error");
              setNotification('Error encountered while submitting');
              setShowAlert(true);
            }
          } else {
            console.log(response.statusText);
          }
        } catch (error) {
          console.log(error.message);
          console.error('Error uploading file:', error);
          // Handle error
        }


      }



      if (value == "redeem-giftcard") {

        let prodid = event.currentTarget.getAttribute('data-prodid');
        const formData = new FormData();
        formData.append('type', "milestone-redeem-giftcard");
        formData.append('milestone', props.milestone);
        formData.append('memberId', props.memberid);
        formData.append('product', prodid);
        formData.append('product_type', "giftcard");

        // console.log("submit report user", formData);
        for (const [key, value] of formData.entries()) {
          console.log(key, value);
        }


        
        try {
          const response = await fetch(webUrl + "/userStats.php", {
            method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
            body: formData,
            // headers: {
            // 	'Content-Type': 'application/json',
            // },
            // body: JSON.stringify(requestData),
          });
          setLoading(false);
          if (response.ok) {
            const result = await response.json();
            console.log("Data:", result);
            if (result.success) {
              console.log("success");

              // if (result.address == "taken") {
              //   console.log("address is taken");
              //   // alert("address taken");
              //   document.getElementById('address_taken_msg').classList.add("show");
              // }

              // //
              // else {

                // if (value == "submit-shipping") {

                //   let get_shippingfee = result.shippingfee;
                //   if (get_shippingfee == "on") {
                //     window.open(result.paymentUrl, '_blank');
                //   }

                //   check_for_payment_status(result.memberId, result.milestone, get_shippingfee);
                //   shippingDetails[0].classList.remove("show");
                //   prodgrp[0].remove();
                //   submitChoice[0].remove();
                //   received[0].remove();
                //   proddetgrp[0].remove();
                //   rewardgrp[0].remove();
                //   shippingfee[0].classList.add("show");


                //   //show congratulations
                //   // alert("congratulations");
                //   // confirmRedeemProd[0].classList.remove("show");
                //   // congrats[0].classList.add("show");
                //   // setNotification('Form submitted successfully');
                //   // setShowAlert(true);

                // }

                // else if (value == "submit") {
                  setNotification('Reward redeemed successfully');
                  setShowAlert(true);
                  props.onHide();
                // }
              // }

            } else {
              console.log("error");
              setNotification('Error encountered while submitting');
              setShowAlert(true);
            }
          } else {
            console.log(response.statusText);
          }
        } catch (error) {
          console.log(error.message);
          console.error('Error uploading file:', error);
          // Handle error
        }











      }

      /*
      const buttons1 = document.querySelectorAll('.isBtn'); // Adjust selector according to your HTML structure
      buttons1.forEach((button) => {
        button.classList.remove('clicked');
      });
      event.target.classList.add('clicked'); 
      */
    };

    const buttons = document.querySelectorAll('.isBtn'); // Adjust selector according to your HTML structure
    buttons.forEach((button) => {
      button.addEventListener('click', handleButtonClick);
    });

    const radios = document.querySelectorAll('.isRadio');
    radios.forEach((radio) => {
      radio.addEventListener('change', handleRadioClick);
    });


    // Cleanup function to remove event listeners when component unmounts
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('click', handleButtonClick);
      });

      radios.forEach((radio) => {
        radio.removeEventListener('change', handleRadioClick);
      });

      clearTimeout(); 
    };
  });

  const spinComplete = async (options) => {
    console.log(options, "spincomplete");
    call_spintowin(options);
  };

  const call_spintowin = async (options) => {

    console.log("submit ", "yes-spintowin: ", options, props.memberid);
    const formData = new FormData();
    formData.append('options', options);
    formData.append('memberId', props.memberid);
    formData.append('type', "spintowin-redeem");
    formData.append('milestone', props.milestone);
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const response = await fetch(webUrl + "/userStats.php", {
        method: 'POST',
        body: formData,
      });
      // setLoading(false);
      let progressSpintowin1 = document.querySelectorAll('.progress-spintowin');
      progressSpintowin1[0].classList.remove("show");
      console.log("Data:", response);

      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          console.log("success");

          const prodid = result.product_won;
          console.log('prodid:', prodid, '.product-detail-' + prodid + '', "win_status: ", result.win_status);
          let prodgrp1 = document.querySelectorAll('.products-group');
          prodgrp1.forEach(element => {
            element.classList.remove('show');
          });
          // load product details of this product in .proddetgrp
          load_product_details(prodid, "spintowin");

        } else {
          console.log("error");
          setNotification('Error encountered while submitting');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message, 'Error uploading file:', error);
    }
  }


  const load_product_details = async (prodid, loadtype) => {

    const formData = new FormData();
    formData.append('prodid', prodid);
    formData.append('type', 'get-product-details');
    formData.append('loadtype', loadtype);
    formData.append('memberId', props.memberid);

    let proddetgrp = document.querySelectorAll('.product-detail-group');
    let allproddet = document.querySelectorAll('.product-detail');

    proddetgrp[0].innerHTML = "<br><br><br>Loading...<br><br><br><br>";

    try {
      const response = await fetch(webUrl + "/userStats.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: formData,
      });
      // setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {

          proddetgrp[0].innerHTML = result.product_details;
          let proddet = document.querySelectorAll('.product-detail-' + prodid + '');
          allproddet.forEach((det) => {
            det.classList.remove('show');
          });
          proddet[0].classList.add("show");
          let newRandomNumber = Math.floor(Math.random() * 1000); // Generate a new random number
          setUpdateDoc(newRandomNumber);

          // if (result.status == 'paid') {
          //   console.log("payment success");
          //   // timerOn = false;
          //   // congrats[0].classList.add("show");
          //   // shippingfee[0].classList.remove("show");
          // } else {
          //   console.log("waiting for payment");
          // }
        } else {
          console.log("error");
          setNotification('Error encountered while fetching product details');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error:', error);
      // Handle error
    }
  };


  let timerOn = true;
  const check_for_payment_status = async (memberId, milestone, mlshippingfee) => {

    let shippingfee = document.querySelectorAll('.shippingfee');
    let congrats = document.querySelectorAll('.congrats');

    // timerOn = true;
    console.log('Function executed after 2 seconds', memberId, milestone);

    const formData = new FormData();
    formData.append('memberId', memberId);
    formData.append('milestone', milestone);
    formData.append('type', 'shipping-fee-status');

    try {
      const response = await fetch(webUrl + "/checkPaymentStatus.php", {
        method: 'POST',
        body: formData,
      });
      // setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);
        if (result.success) {
          if (result.status == 'paid' || mlshippingfee == "off") {
            console.log("payment success");
            timerOn = false;
            congrats[0].classList.add("show");
            shippingfee[0].classList.remove("show");
          } else {
            console.log("waiting for payment");
          }
        } else {
          console.log("error");
          setNotification('Error encountered while checking payment status');
          setShowAlert(true);
        }
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error:', error);
      // Handle error
    }

    if (timerOn == true) {
      setTimeout(() => check_for_payment_status(memberId, milestone, mlshippingfee), 5000);
    }

  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ml-reached"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <div data-milestone={props.milestone} dangerouslySetInnerHTML={{ __html: props.html }} />
          {/* <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} value={message} onChange={handleMessageChange} />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Screenshot (optional)</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button type="submit">Send</Button>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
    </>

  );
}

export default UserInfoModal;