import React, { useEffect, useState } from "react"
import { baseUrl, webUrl } from './variables';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import UnlockRewardsModal from './UnlockRewardsModal';
import CPAModal from "./CPAModal";
import GCModal from "./GCModal";
import RedeemProdPointModal from "./RedeemProdPointModal";

function ProfileModal(props) {

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [html, setHtml] = useState(null);

  const [unlockmodalShow, setUnlockmodalShow] = React.useState(false);
  const [cpamodalShow, setCpamodalShow] = React.useState(false);
  const [cpaType, setCpaType] = useState('');

  const [gcmodalShow, setGcmodalShow] = React.useState(false);
  const [gcmml, setGcmml] = React.useState("");

  const [productpoints, setProductpoints] = useState(0);

  const [redeemProdpointModalShow, setRedeemProdpointModalShow] = React.useState(false);
  

  // const [uecount, setUecount] = useState(0)


  // const [show, setShow] = useState(true);

  /*
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }; */


  const handleSubmit = async (event) => {
    // event.preventDefault();
    // setLoading(true);

    // const formData = new FormData();
    // formData.append('file', file);
    // formData.append('message', message);
    // formData.append('type', "new");
    // formData.append('me', props.me);
    // formData.append('idToCall', props.idToCall);
    // formData.append('memberId', props.memberId);
    // formData.append('calltype', props.calltype);
    // formData.append('roomRowId', props.roomRowId);
    // formData.append('roomId', props.roomId);
    // formData.append('caller', props.caller);

    // // console.log("submit report user", formData);
    // // for (const [key, value] of formData.entries()) {
    // //   console.log(key, value);
    // // }
    // try {
    //   const response = await fetch(webUrl + "/reportUser.php", {
    //     method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
    //     body: formData,
    //     // headers: {
    //     // 	'Content-Type': 'application/json',
    //     // },
    //     // body: JSON.stringify(requestData),
    //   });
    //   setLoading(false);
    //   if (response.ok) {
    //     const result = await response.json();
    //     console.log("Data:", result);
    //     if (result.success) {
    //       console.log("success");
    //       setNotification('Form submitted successfully');
    //       setShowAlert(true);
    //       props.onHide();
    //     } else {
    //       console.log("error");
    //       setNotification('Error encountered while submitting');
    //       setShowAlert(true);
    //     }
    //   } else {
    //     console.log(response.statusText);
    //   }
    // } catch (error) {
    //   console.log(error.message);
    //   console.error('Error uploading file:', error);
    //   // Handle error
    // }

  }


  const handleModalClose = () => {
    // props.onHide();
    setFile(null);
    setMessage('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };



  useEffect(() => {
    // setUecount(uecount + 1);
    // console.log("useffect", uecount);

    const formData = new FormData();

    formData.append('type', "profileInfo");
    formData.append('me', props.me);
    formData.append('memberid', props.memberid);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/userProfile.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        console.log("Data:", response.body);

        if (response.ok) {
          const result = await response.json();
          console.log("Data:", result);
          if (result.success) {
            console.log("success");
            console.log(result);
            setHtml(result.html);
            setProductpoints(result.member_product_points);
            // if (result.data.mlinfo.milestone_popup != "") {
            //   setHtml(result.data.mlinfo.html);
            //   // setUnlockmodalShow(true);
            // }
          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }
        // if (response.ok) {    
        //   const result = await response.json();   
        //   console.log("Data:", result);
        //   }     
        // if (!response.ok) {     
        //   throw new Error('Failed to fetch data');  
        // }   
        // const jsonData = await response.json();     
        // setData(jsonData);   
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    // if (uecount == 0) {
      fetchData();
    // }



    const handleRadioClick = async (event) => {
      const value = event.currentTarget.getAttribute('data-value');
      const type = event.currentTarget.getAttribute('data-type');
      console.log('Clicked:', event.currentTarget.innerText, type, value);

      if (type == "user_adrs") {
        let adr_form = document.querySelectorAll('.new-address-form');
        if (value == "new") {
          adr_form[0].classList.remove("hide");
          adr_form[0].classList.add("show");
        } else {
          adr_form[0].classList.remove("show");
          adr_form[0].classList.add("hide");
        }
      }
    };

    // Add event listeners to the buttons after HTML content is set
    const handleButtonClick = async (event) => {
      const value = event.target.getAttribute('data-value');
      console.log('Clicked:', event.target.innerText, value);
      let myprofgrp = document.querySelectorAll('.user-profile .my-profile');
      let profile_rewards = document.querySelectorAll('.user-profile .profile-rewards');

      if (value == "show-profile-rewards") {
        myprofgrp[0].classList.remove("show");
        profile_rewards[0].classList.add("show");
      }

      if (value == "close-profile") {
        props.onHide();
      }

      if (value == "close-profile-rewards") {
        myprofgrp[0].classList.add("show");
        profile_rewards[0].classList.remove("show");
      }

      if (value == "tracking-url") {
        const url = event.target.getAttribute('data-tracking-url');
        window.open(url, "_blank");
      }

      if (value == "edit-shipping") {
        const id = event.target.getAttribute('data-id');
        let form = document.querySelectorAll('.shipping-details[data-id="' + id + '"]');
        form[0].classList.add("show");
        // const url = event.target.getAttribute('data-tracking-url');
        // window.open(url,"_blank");
      }

      if (value == "submit-shipping-save") {
        console.log("submit-shipping-save");
        const id = event.target.getAttribute('data-id');
        await submit_shipping_edit(id);
      }

      if (value == "choose-giftcard") {
        const id = event.target.getAttribute('data-id');
        openGC(id);
      }


    };

    const submit_shipping_edit = async (id) => {


      console.log(id);
      let firstname = document.querySelectorAll('.firstname-' + id + '')[0].value;
      let lastname = document.querySelectorAll('.lastname-' + id + '')[0].value;
      let country = document.querySelectorAll('.country-' + id + '')[0].value;
      let mobile = document.querySelectorAll('.mobile-' + id + '')[0].value;
      let address = document.querySelectorAll('.address-' + id + '')[0].value;
      let state = document.querySelectorAll('.state-' + id + '')[0].value;
      let zip = document.querySelectorAll('.zip-' + id + '')[0].value;
      let usernotes = document.querySelectorAll('.usernotes-' + id + '')[0].value;
      let address_id = document.querySelector('input[name="user_adrs"]:checked').value;


      const shipData = new FormData();
      shipData.append('type', "shipping-edit");
      shipData.append('id', id);
      shipData.append('firstname', firstname);
      shipData.append('lastname', lastname);
      shipData.append('country', country);
      shipData.append('mobile', mobile);
      shipData.append('address', address);
      shipData.append('state', state);
      shipData.append('zip', zip);
      shipData.append('usernotes', usernotes);
      shipData.append('memberid', props.memberid);
      shipData.append('address_id', address_id);

      // console.log(shipData);
      for (const [key, value] of shipData.entries()) {
        console.log(key, value);
      }

      try {
        const response = await fetch(webUrl + "/userStats.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: shipData,
          // headers: {
          // 	'Content-Type': 'application/json',
          // },
          // body: JSON.stringify(requestData),
        });
        setLoading(false);
        if (response.ok) {
          const result = await response.json();
          console.log("Data:", result);
          if (result.success) {
            console.log("success");

            if (result.address == "taken") {
              console.log("address is taken");
              // alert("address taken");
              document.getElementById('address_taken_msg').classList.add("show");
            }

            //
            else {

              let form = document.querySelectorAll('.shipping-details[data-id="' + id + '"]');
              form[0].classList.remove("show");
              setNotification('Details submitted successfully');
              setShowAlert(true);

              // props.onHide();

            }

          } else {
            console.log("error");
            setNotification('Error encountered while submitting');
            setShowAlert(true);
          }
        } else {
          console.log(response.statusText);
        }
      } catch (error) {
        console.log(error.message);
        console.error('Error uploading file:', error);
        // Handle error
      }

    }

    const buttons = document.querySelectorAll('.isBtn'); // Adjust selector according to your HTML structure
    buttons.forEach((button) => {
      button.addEventListener('click', handleButtonClick);
    });

    const radios = document.querySelectorAll('.isRadio');
    radios.forEach((radio) => {
      radio.addEventListener('change', handleRadioClick);
    });

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('click', handleButtonClick);
      });

      radios.forEach((radio) => {
        radio.removeEventListener('change', handleRadioClick);
      });


    };
  });

  const unlockearly = () => {
    setUnlockmodalShow(true);
    // props.onHide(); 
  };


  const openCPA = (t) => {
    console.log(t);
    setUnlockmodalShow(false);
    setCpamodalShow(true);
    setCpaType(t);
  };

  const openGC = (id) => {
    console.log("openGC");
    setGcmodalShow(true);
    setGcmml(id);
  };

  const openRedeemProdPoint = () => {
    console.log("openRedeemProdPoint");
    setRedeemProdpointModalShow(true);
    if(productpoints >= 2){
      console.log("open cats");
    } else {
      console.log("open cats - restricted");
    }
  };

  const hideAllModals = () => {
    setRedeemProdpointModalShow(false)
    props.onHide();
    setNotification('Details submitted successfully');
    setShowAlert(true);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ml-reached"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <div className='text-center'>
            <Button variant="danger" className="unlock-early" onClick={() => unlockearly()}>Unlock Rewards Early</Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading && <div class=''>Submitting...</div>} &nbsp;
          {notification && <div>{notification}</div>}
        </Modal.Footer>

      </Modal>
      <Alert show={showAlert} variant="success" className="alert-notify fixed-top w-30 text-center p-2">
        {notification}
        <div className="d-inline">
          <Button onClick={() => setShowAlert(false)} variant="outline-success">
            X
          </Button>
        </div>
      </Alert>
      <UnlockRewardsModal show={unlockmodalShow} onOpenCPA={(t) => openCPA(t)} onHide={() => setUnlockmodalShow(false)} type={""} memberid={props.memberid} />
      <CPAModal show={cpamodalShow} onHide={() => setCpamodalShow(false)} cpaType={cpaType} memberid={props.memberid} productpoints={productpoints} openRedeemProdPoint={() => openRedeemProdPoint()} />
      <GCModal show={gcmodalShow} onHide={() => setGcmodalShow(false)} memberid={props.memberid} gcmml={gcmml} />
      <RedeemProdPointModal show={redeemProdpointModalShow}  onHide={() => setRedeemProdpointModalShow(false)}  onHideAll={() => hideAllModals()} memberid={props.memberid}></RedeemProdPointModal>
    </>

  );
}

export default ProfileModal;