import React, { Component } from "react";
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { baseUrl, webUrl } from './variables';
import countries from "./countries";
import interests from "./interests";
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function PromoForm(props) {

  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState([]);
  // const [file, setFile] = useState(null);
  const [gender, setGender] = useState('');
  const [interest, setInterest] = useState('');
  const [country, setCountry] = useState('');
  const [sameuser, setSameuser] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [urlbtn, setUrlbtn] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [submitType, setSubmitType] = useState(null);
  const [promoid, setPromoid] = useState('');
  const [adpoints, setAdpoints] = useState(0);
  const [maxadpoints, setMaxadpoints] = useState(0);

  const [defaultFiles, setDefaultFiles] = useState([]);
  const [defaultThumbs, setDefaultThumbs] = useState("");
  const [showExplicitPopup, setShowExplicitPopup] = useState(false);

  
  // const [show, setShow] = useState(true);

  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  //   console.log(event.target.files[0]);
  // };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };
  const handleUrlbtnChange = (event) => {
    setUrlbtn(event.target.value);
  };
  

  const handleAdPointChange = (event) => {
    // console.log(adpoints, maxadpoints);
    if (event.target.value <= parseInt(maxadpoints)) {
      setAdpoints(event.target.value);
    }
    else {
      setAdpoints(parseInt(maxadpoints));
    }
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleInterestChange = (event) => {
    setInterest(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleSameuserChange = (event) => {
    setSameuser(event.target.value);
    // console.log(sameuser);
  };


  const onDefaultFileDelete = (index) => {
    const updatedImages = [...defaultFiles]; // Create a copy of the images array
    updatedImages.splice(index, 1); // Remove the image at the specified index
    setDefaultFiles(updatedImages); // Call the onDelete function with the updated array
  };


  const clearForm = (event) => {
    setTitle("");
    setDescription("");
    setInterest("");
    setCountry("");
    setUrl("");
    setUrlbtn("");
    setGender("");
    setSameuser("");
    setDefaultFiles([]);
  };


  const onDrop = (dfiles) => {
    setFiles(dfiles);
    // Map through files and create previews


    setPreview(dfiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));

    // const filesWithPreview = dfiles.map(file => Object.assign(file, {
    //   preview: URL.createObjectURL(file)
    // }));

    // Update state with files and their previews
    // setPreview({ files: filesWithPreview });
  };

  const thumbs = preview.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));


  useEffect(() => {

    // clearForm();
    const formData = new FormData();

    formData.append('type', "fetch-by-id");
    formData.append('formid', props.formid);
    formData.append('formtype', props.formtype);
    formData.append('memberid', props.memberid);

    const fetchData = async () => {
      try {
        const response = await fetch(webUrl + "/promos.php", {
          method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
          body: formData,
        });
        // console.log("fetchDetails Data:", response);

        if (response.ok) {
          const result = await response.json();
          console.log("fetchDetails Data:", result);
          if (result.success) {
            // console.log("success");            console.log(result);
            console.log("ad_points", result.max_ad_points);
            setMaxadpoints(result.max_ad_points);

            if (result.promo.id != undefined) {
              setDescription(result.promo.description);
              setTitle(result.promo.title);
              setUrl(result.promo.url);
              setUrlbtn(result.promo.urlbtn);
              setCountry(result.promo.country);
              setInterest(result.promo.interest);
              setGender(result.promo.gender);
              setSameuser(result.promo.sameuser);
              setAdpoints(result.promo.ad_points);



              if (result.promo.media != "") {
                let spl = result.promo.media.split(",");
                spl = spl.filter(item => item.trim() !== '');
                setDefaultFiles(spl);
              }
            }

            if (props.formtype == "usetemplate") {
              setPromoid("new");
            } else if (props.formtype == "edit") {
              setPromoid(props.formid);
            } else {
              setPromoid("new");
            }

          } else {
            console.log("error");
          }
        } else {
          console.log(response.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();



  }, []);



  const formSubmit = async (event, type) => {
    event.preventDefault();
    // console.log(event, type);
    setSubmitType(type);
    setShowExplicitPopup(true);

  };

  const explicitYes = async (type) => {
    await handleSubmit(submitType, "yes");
  }

  const explicitNo = async (event, type) => {
    await handleSubmit(submitType, "no");
  }

  const handleSubmit = async (promo_type, explicit) => {
    
    setLoading(true);

    // console.log(`${submitType} was clicked!`);

    const formData = new FormData();
    // formData.append('file', file);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('url', url);
    formData.append('urlbtn', urlbtn);
    formData.append('gender', gender);
    formData.append('sameuser', sameuser);
    formData.append('country', country);
    formData.append('interest', interest);
    formData.append('type', "create-promo");
    formData.append('me', props.me);
    formData.append('memberid', props.memberid);
    formData.append('promo_type', promo_type);
    formData.append('default_images', defaultFiles.join(","));
    formData.append('promoid', promoid);
    formData.append('ad_points', adpoints);
    formData.append('explicit', explicit);


    if (files != null) {
      files.forEach(file => {
        formData.append('myimages[]', file, file.name);
      });

    }


    // files.forEach(file => {
    //   formData.append('myimages[]', file, file.name);
    // });

    // console.log("submit report user", formData);
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    try {
      const response = await fetch(webUrl + "/promos.php", {
        method: 'POST', // Use the appropriate HTTP method (POST, GET, etc.)
        body: formData,
        // headers: {
        // 	'Content-Type': 'application/json',
        // },
        // body: JSON.stringify(requestData),
      });
      setLoading(false);
      if (response.ok) {
        const result = await response.json();
        console.log("Data:", result);

        if (result.success) {
          console.log("success");
          setNotification('Form submitted successfully');
          setShowAlert(true);
          props.onHide();
        } else {
          console.log("error");
          setNotification('Error encountered while submitting');
          setShowAlert(true);
        }

      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.log(error.message);
      console.error('Error uploading file:', error);
      // Handle error
    }

  }


  const handleModalClose = () => {
    // props.onHide();
    // setFile(null);
    // setDescription('');
    setLoading(false); // Reset loading state when modal is closed
    setNotification(''); // Clear notification message when modal is closed
  };

  return (
    <>
      <Form>

        {props.formid}
        {props.formtype}

        <Form.Group className="promo-form-group mb-3" controlId="exampleForm.ControlTitle1">
          <Form.Label>Title</Form.Label>
          <Form.Control as="input" value={title} onChange={handleTitleChange} placeholder="Promo Title"/>
        </Form.Group>

        <Form.Group className="promo-form-group mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" rows={3} value={description} onChange={handleDescriptionChange}  placeholder="Promo brief description"/>
        </Form.Group>

        {/* {props.formid} {props.formtype} */}
        {/* {defaultFiles} */}
        {/* {defaultThumbs} */}


        <div className="promo-form-group mb-3">
          <label htmlFor="exampleInputAdpoint144">Use Ad Points (Max. {maxadpoints}) (1 Ad point = Reach 1 user)</label>
          <input type="number" name="adpoints" value={adpoints} step={1} onChange={handleAdPointChange}
            className="form-control" id="exampleInputAdpoint144" placeholder="Use Ad Points" />
        </div>

        <div className="promo-form-group mb-3 promo-url">
          <label htmlFor="exampleInputUrl1">Target Url Link</label>
          <input type="text" name="url" value={url} onChange={handleUrlChange} className="form-control" id="exampleInputUrl1"
            placeholder="Enter url"/>
        </div>

        <div className="promo-form-group mb-3 promo-url-btn">
          <label htmlFor="exampleInputUrl121">Url Link Text</label>
          <input type="text" name="urlbtn" value={urlbtn} onChange={handleUrlbtnChange} className="form-control" id="exampleInputUrl121"
            placeholder="Enter Url link button text"/>
        </div>

        <div className="form-group promo-form-group mb-3">
          <label htmlFor="exampleInputCountry1">Country</label>
          <select
            name="country"
            value={country}
            onChange={handleCountryChange}
            className="form-control"
          >
            <option value="all">All (Recommended to reach more users)</option>
            {countries.map(country => (
              <option key={country.value} value={country.value}>{country.label}</option>
            ))}
          </select>
        </div>


        <div className="form-group promo-form-group mb-3">
          <label htmlFor="exampleInputInterest1">Interest</label>
          <select
            name="interest"
            value={interest}
            onChange={handleInterestChange}
            className="form-control"
          >
            <option value="all">All (Recommended to reach more users)</option>
            {interests.map(interest => (
              <option key={interest.value} value={interest.value}>{interest.label}</option>
            ))}
          </select>
        </div>

        {/* {promoid} */}

        <div className="form-group promo-form-group mb-3">
          <label htmlFor="exampleInputGender1">Gender</label>
          <select
            name="gender"
            value={gender}
            onChange={handleGenderChange}
            className="form-control"
          >
            <option value="">Pick Gender</option>
            <option key="Male" value="Male">Male</option>
            <option key="Female" value="Female">Female</option>
            <option key="Any" value="Any">Any Gender</option>
          </select>
        </div>


        <div className="form-group promo-form-group mb-3">
          <label htmlFor="exampleInputSameuser1">Show Promo to same user</label>
          <select
            name="sameuser"
            value={sameuser}
            onChange={handleSameuserChange}
            className="form-control"
          >
            <option key="sameuser-true" value="multiple">Show promo to same user (more than once)</option>
            <option key="sameuser-false" value="single">Show promo only once to a user</option>
          </select>
        </div>

        {/* <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Screenshot (optional)</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group> */}


        <div className="dropzone-wrap promo-form-group mb-3">
          <Dropzone
            onDrop={onDrop}
            accept={{
              image: ['image/png', 'image/gif', 'image/jpeg']
            }}
            // accept= "image/png, image/gif, image/jpeg"
            minSize={0}
            maxSize={5242880}
            multiple
          >
            {({ getRootProps, getInputProps }) => (
              <section className="">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>Click here or drop a file to upload!</p>
                </div>
                <aside style={thumbsContainer}>
                  {thumbs}
                </aside>
                {/* <aside style={thumbsContainer}>
                  { preview != null ? 
                    preview.files.forEach(file => (
                      <div style={thumb} key={file.name}><div style={thumbInner}>
                        <img src={file.preview} style={img} alt={file.name} />
                        <p>{file.name}</p>
                      </div></div>
                    )) : null
                  }
                </aside> */}

              </section>
            )}
          </Dropzone>
        </div>

        <div>
          {defaultFiles.map((image, index) => (
            <div key={index} className="image-thumb">
              <img
                key={index}
                src={webUrl + "/uploads/" + image}
                // alt={`Thumbnail ${index}`}
                style={{ width: '100px', height: '100px', margin: '5px' }}
              />
              <button className="delete-thumb" onClick={() => onDefaultFileDelete(index)}> X </button>

            </div>
          ))}
        </div>

        {/* <Button type="button" onClick={handleBtnSubmit('post')}>Submit via Button Click</Button> */}

        <Button type="button" onClick={(event) => formSubmit(event, 'post')}>Post</Button> &nbsp; &nbsp; 
        <Button type="button" onClick={(event) => formSubmit(event, 'template')}>Save as Template</Button>

        {/* <Button type="submit" onClick={(event) => setSubmitType('post')}>Post</Button>
        <Button type="submit" onClick={(event) => setSubmitType('template')}>Save Template</Button> */}
      </Form>

      {showExplicitPopup == true ?
        <div className="explicit-promo-popup">
          <img src="images/monkey-close-eyes.png" />
          <h4 className="msg">Is your promo ad explicit content? </h4>
          <p>Pre-Blur will be applied when users view, and they can decide to unblur anytime.</p>
          <Button variant="primary" className="explicit-promo-btn yes" onClick={() => explicitYes()}>Yes</Button>
          <Button variant="primary" className="explicit-promo-btn no" onClick={() => explicitNo()}>No</Button>
          <p>Not applying pre-blur to explicit content will result in a ban, if you need an idea on what is considered explicit visit: </p>
          <h5>What's Prohibited? <img src="images/woman-saying-no.png" /></h5>
        </div> : null}

    </>

  );
}


export default PromoForm;